import React from "react";

// import configData from "../config.json";
import '../component_css/Admin.css';
import { constructLeagueConfigs, parseSeasonFromPath, getGameDataInfo, getRosterInfo, getPokemonIDDetailsInfo, getPlayerUserNames, isNumber } from './helpers';

const configData = constructLeagueConfigs()

class AdminSubPanel extends React.Component {
    constructor() {
        super();
        this.seasonNum = parseSeasonFromPath(window.location.pathname);
        this.changeTextField = this.changeTextField.bind(this);
        this.rosterData = {};
        this.pokemonData = {};
        this.userData = {};
    }

    getRosterData() {
        return getRosterInfo(this.seasonNum).then(
            results => {
                this.rosterData = results;
            }
        )
    }

    getPokemonData() {
        // get all the pokemon in the database
        return getPokemonIDDetailsInfo(this.seasonNum).then(
            results => {
                this.pokemonData = results;
            }
        )
    }

    getPlayerUsernames() {
        return getPlayerUserNames(this.seasonNum).then(
            results => {
                this.userData = results;
            }
        )
    }

    getGameData() {
        return getGameDataInfo(this.seasonNum);
    }

    changeTextField(e) {
        this.setState({
            textData: e.target.value
        });
    }
}


class ShowdownUsernamePanel extends AdminSubPanel {
    constructor() {
        super();
        this.state = {
            userData: {},
            textData: ""
        }
        this.getPlayerUsernames = this.getPlayerUsernames.bind(this);
        this.initializeTextData = this.initializeTextData.bind(this);
    }

    initializeTextData() {
         const playerNames = this.userData["players"]
        const playerAccounts = this.userData["player_accounts"]

        if (playerNames === undefined) {
            return;
        }
        let newText = "";
        playerNames.forEach((pName) => {
            const thisPlayersNames = playerAccounts[pName];
            thisPlayersNames.forEach((pAcctName) => {
                newText = newText.concat(pName + ", " + pAcctName)
                newText = newText.concat("\n")
            });
        });
        this.setState({textData: newText})
    }

    componentDidMount() {
        this.getPlayerUsernames().then(() => {
            this.initializeTextData()
        })
    }

    onSubmit(event) {
        event.preventDefault();
        var reqData = new FormData()
        reqData.append('textData', JSON.stringify(this.state.textData))
        this.props.submitFunc("showdown_username_changes", reqData)
    }

    render() {
        return <div>
            <h3>Edit Player Showdown Usernames</h3>
            <div className="row">
                <form onSubmit={(e) => {this.onSubmit(e)}}>
                    <div className="col-sm-12">
                    <p>
                        <i>Please ensure these are valid PS names, for example</i> <b>King,Of Galactic,West</b> <i>would actually be <b>KingOfGalacticWest</b></i>.
                        Separate the player name from their account with a comma.</p>
                    <textarea
                        value={this.state.textData}
                        name="rawInput" rows={20} cols={40}
                        onChange={this.changeTextField}/>

                        <input className="form-submit-btn" type="submit" value="Submit" />
                    </div>
                </form>
            </div>
            
        </div>
    }
}


class RosterChangePanel extends AdminSubPanel {
    constructor() {
        super();
        this.addInputRow = this.addInputRow.bind(this);
        this.state = {
            rosterData: {},
            pokemonData: [],
            inputRows: [],
            textData: ""
        }
        this.deleteRow = this.deleteRow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.changeInputRowPlayer = this.changeInputRowPlayer.bind(this);
        this.changeInputRowPokemon = this.changeInputRowPokemon.bind(this);
        this.changeInputRowDate = this.changeInputRowDate.bind(this);
        
    }

    componentDidMount() {
        this.getRosterData()
        this.getPokemonData()
    }

    changeInputRowPlayer(rowIdx, newPlayer1, newPlayer2) {
        const newRows = this.state.inputRows;
        const player1Change = newRows[rowIdx].player1 !== newPlayer1;
        const player2Change = newRows[rowIdx].player2 !== newPlayer2;

        newRows[rowIdx] = {
            player1: newPlayer1,
            pokemon1: (player1Change ? "" : newRows[rowIdx].pokemon1),
            player2: newPlayer2,
            pokemon2: (player2Change ? "" : newRows[rowIdx].pokemon2),
            date: newRows[rowIdx].date,
            time: newRows[rowIdx].time
        }
        this.setState({
            inputRows: newRows
        });
    }

    changeInputRowPokemon(rowIdx, newPoke1, newPoke2) {
        const newRows = this.state.inputRows;
        newRows[rowIdx] = {
            player1: newRows[rowIdx].player1,
            pokemon1: newPoke1,
            player2: newRows[rowIdx].player2,
            pokemon2: newPoke2,
            date: newRows[rowIdx].date,
            time: newRows[rowIdx].time
        }
        this.setState({
            inputRows: newRows
        });
    }

    changeInputRowDate(rowIdx, newDate, newTime) {
        const newRows = this.state.inputRows;
        newRows[rowIdx].date = newDate;
        newRows[rowIdx].time = newTime
        this.setState({
            inputRows: newRows
        });
    }

    deleteRow(e, rowIdx) {
        e.preventDefault()
        const newRows = this.state.inputRows;
        newRows.splice(rowIdx, 1);
        this.setState({
            inputRows: newRows
        });
    }

    onSubmit(event) {
        event.preventDefault();
        var reqData = new FormData()
        reqData.append('data', JSON.stringify(this.state.inputRows))
        reqData.append('textData', JSON.stringify(this.state.textData))
        this.props.submitFunc("roster_changes", reqData)
    }

    buildInputRows() {
        const inputRowsRender = []
        const playerRosterInfo = this.rosterData;
        const allPokemonInfo = this.pokemonData.base;
        const deleteRowFunc = this.deleteRow;

        this.state.inputRows.forEach((datum, idx) => {
            // Set Player Information
            const player1Options = [];
            const player2Options = [];
            Object.keys(playerRosterInfo).forEach((name) => {
                player1Options.push(<option value={name}>{name}</option>)
                if (name !== datum.player1) {
                    player2Options.push(<option value={name}>{name}</option>)
                }
            });

            // Set Pokemon Information
            const pokemon1Options = [];
            const pokemon2Options = [];
            
            if (datum.player1 !== "") {
                playerRosterInfo[datum.player1].roster.forEach((pokemonID) => {
                    const pokemonName = this.pokemonData.base[pokemonID].pokemon_name;
                    pokemon1Options.push(
                        <option value={pokemonName}>
                            {pokemonName}
                        </option>)
                })
            }
            if (datum.player2 !== "") {
                playerRosterInfo[datum.player2].roster.forEach((pokemonID) => {
                    const pokemonName = this.pokemonData.base[pokemonID].pokemon_name;
                    pokemon2Options.push(
                        <option value={pokemonName}>
                            {pokemonName}
                        </option>)
                })
            } else {
                Object.keys(allPokemonInfo).forEach((pokemonID) => {
                    const pokemonName = this.pokemonData.base[pokemonID].pokemon_name
                    pokemon2Options.push(
                        <option value={pokemonName}>
                            {pokemonName}
                        </option>)
                })
            }

            inputRowsRender.push(<div>
                {idx !== 0 ? <hr /> : ""}
                <label>Date/Time:&nbsp;
                    <input type="date"
                        name={"date_" + idx}
                        value={datum.date}
                        onChange={(e) => {this.changeInputRowDate(idx, e.target.value, datum.time)}}
                    />
                    <input type="time"
                    name={"time_" + idx}
                        value={datum.time}
                        onChange={(e) => {this.changeInputRowDate(idx, datum.date, e.target.value)}}
                    />
                </label><br/>
                <label>Player 1:&nbsp;
                    <select name={"player1SelectRow_" + idx}
                        value={datum.player1}
                        onChange={(e) => this.changeInputRowPlayer(idx, e.target.value, datum.player2)}>
                        {player1Options}
                    </select>
                </label> &nbsp;
                <label>Pokemon 1:&nbsp;
                    <select name={"pokemon1SelectRow_" + idx}
                        value={datum.pokemon1}
                        onChange={(e) => this.changeInputRowPokemon(idx, e.target.value, datum.pokemon2)}
                    >
                        <option value=""></option>
                        {pokemon1Options}
                    </select>
                </label><br/>
                <label>Player 2:&nbsp;
                    <select name={"player2SelectRow_" + idx}
                        value={datum.player2}
                        onChange={(e) => this.changeInputRowPlayer(idx, datum.player1, e.target.value)}>
                        <option value=""></option>
                        {player2Options}
                    </select>
                </label> &nbsp;
                <label>Pokemon 2:&nbsp;
                    <select name={"pokemon2SelectRow_" + idx}
                        value={datum.pokemon2}
                        onChange={(e) => this.changeInputRowPokemon(idx, datum.pokemon1, e.target.value)}
                    >
                        <option value=""></option>
                        {pokemon2Options}
                    </select>
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button className="btn btn-danger"
                    onClick={(e) => {deleteRowFunc(e, idx)}}>
                        Delete Row
                    </button>
            </div>)
        });

        return <div>
            {inputRowsRender}
        </div>
    }

    addInputRow() {
        const newState = this.state;
        newState.inputRows.push(
            {
                player1: Object.keys(this.rosterData)[0],
                pokemon1: "",
                player2: "",
                pokemon2: "",
                date: "",
                time: ""
            }
        )
        this.setState(newState);
    }

    render() {
        return <div>
            <h3>Roster Changes</h3>
            <button className="btn btn-dark" onClick={this.addInputRow}>Add Row</button>
            <div className="row">
                <form onSubmit={(e) => {this.onSubmit(e)}}>
                    <div className="col-sm-12">
                        Provide the Roster Change Info Individually <br />
                        {this.buildInputRows()}
                    </div>
                    <div className="col-sm-12 or">-OR-</div>
                    <div className="col-sm-12">
                        <p>Please provide Tab-separated output</p>
                        <textarea
                            placeholder="Date (MM/DD/YYYY), 24-hr Time (HH:MM), Player 1, Pokemon 1, Player 2, Pokemon 2"
                            name="rawInput" rows={10} cols={70}
                            onChange={this.changeTextField}/>
                    </div>

                    <input className="form-submit-btn" type="submit" value="Submit" />
                </form>
            </div>
        </div>
    }
}


class GameSchedulePanel extends AdminSubPanel {
    constructor() {
        super();
        this.state = {
            gameData: {},
            textData: ""
        }
    }

    onSubmit(event) {
        event.preventDefault();
        var reqData = new FormData()
        reqData.append('textData', JSON.stringify(this.state.textData))
        this.props.submitFunc("add_game_data", reqData)
    }

    render() {
        return <div>
            <h3>Add Games</h3>
            <div className="row">
                <form onSubmit={(e) => {this.onSubmit(e)}}>
                    <div className="col-sm-12">
                    <p>Data order is
                        <ol>
                            <li>Game Round #</li>
                            <li>Player 1 Name</li>
                            <li>Player 2 Name</li>
                            <li>Playoff Game Flag (0/1)</li>
                            <li>Playoff Game Number</li>
                        </ol>
                        If not a playoff game, put 0 for Playoff Game Number
                    </p>
                    <textarea
                        value={this.state.textData}
                        name="rawInput" rows={20} cols={40}
                        placeholder={"Game Round #, Player 1 Name, Player 2 Name, Playoff Game (0/1), Playoff Game Number"}
                        onChange={this.changeTextField}/>

                        <input className="form-submit-btn" type="submit" value="Submit" />
                    </div>
                </form>
            </div>
        </div>
    }
}


class CreateUserPanel extends AdminSubPanel {
    constructor() {
        super();
        this.state = {
            textData: ""
        }
    }

    onSubmit(event) {
        event.preventDefault();
        var reqData = new FormData()
        reqData.append('textData', JSON.stringify(this.state.textData))
        this.props.submitFunc("create_users", reqData)
    }

    render() {
        return <div>
            <h3>Create users</h3>
            <div className="row">
                <form onSubmit={(e) => {this.onSubmit(e)}}>
                    <div className="col-sm-12">
                    <p>
                        <i>Please provide the player names, conference, and division information.</i>
                    </p>
                    <textarea
                        value={this.state.textData}
                        onChange={this.changeTextField}
                        name="rawInput2" rows={20} cols={40}
                        placeholder={"Name, Conference, Division"}
                        />

                        <input className="form-submit-btn" type="submit" value="Submit" />
                    </div>
                </form>
            </div>
        </div>
    }
}


class PreliminaryDraftPanel extends AdminSubPanel {
    constructor() {
        super();
        this.state = {
            wipeTable: false,
            textData: "",
            draftType: "snake"
        }
        this.updateWipeTableToggle = this.updateWipeTableToggle.bind(this);
        this.buildWipeTableToggles = this.buildWipeTableToggles.bind(this);
    }

    updateWipeTableToggle(newVal) {
        this.setState({wipeTable: newVal})
    }

    buildWipeTableToggles() {
        return <div className="btn-group wipe-table-toggle" role="group" aria-label="Third group">
            <button type="button" onClick={() => this.updateWipeTableToggle(false)} className={"btn " + (this.state.wipeTable ? "btn-dark" : "btn-primary")}>Don't Wipe Draft Table</button>
            <button type="button" onClick={() => this.updateWipeTableToggle(true)} className={"btn " + (this.state.wipeTable ? "btn-primary" : "btn-dark")}>Wipe Draft Table</button>
        </div>
    }

    onSubmit(event) {
        event.preventDefault();
        var reqData = new FormData()
        reqData.append('textData', JSON.stringify(this.state.textData))
        reqData.append('wipeTable', this.state.wipeTable)
        reqData.append("draftType", this.state.draftType)
        this.props.submitFunc("preliminary_draft", reqData)
    }

    render() {
        return <div>
            <h3>Preliminary Draft</h3>
            <div className="row">
                <form onSubmit={(e) => {this.onSubmit(e)}}>
                    <div>
                    <div>
                        {this.buildWipeTableToggles()}
                    </div>
                    <p>
                        Please provide tab-separated output below; the first line should be the player names followed by the draft picks.<br/>
                        Ensure the pokemon names match the database.<br/>
                        <b>Note:</b> This <i>will</i> overwrite the roster data for these players.
                    </p>
                    <p>
                        For example (replacing commas with tabs):<br/>
                        &nbsp;&nbsp;&nbsp;Ali, Garrison, John, Muchi<br/>
                        &nbsp;&nbsp;&nbsp;Spinda, Meloetta, Tapu Bulu, Xurkitree<br/>
                        &nbsp;&nbsp;&nbsp;Zygarde, Pikachu, Hitmontop, Blastoise<br/>
                    </p>
                    <textarea
                        value={this.state.textData}
                        name="rawInput" rows={20} cols={100}
                        onChange={this.changeTextField}/>

                        <input className="form-submit-btn" type="submit" value="Submit" />
                    </div>
                </form>
            </div>
        </div>
    }
}


class RecomputeGameStats extends AdminSubPanel {
    constructor() {
        super();
        this.buttonClick = this.buttonClick.bind(this);
        this.state = {
            loading: false
        }

    }

    buttonClick() {
        this.setState({loading: true})
        var reqData = new FormData()
        this.props.submitFunc("recompute_stats", reqData)
    }

    render() {
        let subcomponent = <button className="btn btn-dark" onClick={this.buttonClick}>Trigger Recompute</button>
        if (this.state.loading === true) {
            subcomponent = <div>Please wait, page will reload upon completion
                <img className="loading-img" alt="" src={process.env.PUBLIC_URL + "/loading_hourglass.gif"} /></div>
        }

        return <div>
            <h3>Recompute Stats <br/></h3>
            {subcomponent}
        </div>
    }
}


class ModifyUnplayedGames extends AdminSubPanel {
    constructor() {
        super();
        this.state = {
            "gamesToDelete": [],
            "playersToDelete": [],
            "gamesToModify": {},
            "gamesToOverride": []
        }
        this.currentOverrideID = 0;
        this.gameData = [];
        this.rosterData = {};
        this.playerNames = [];
        this.unplayedGames = [];
        this.addOverrideRow = this.addOverrideRow.bind(this);
        this.generateGameOverridePanel = this.generateGameOverridePanel.bind(this);
        this.onOverrideSubmit = this.onOverrideSubmit.bind(this);

        this.currentDeleteID = 0;
        this.addPlayerDeleteRow = this.addPlayerDeleteRow.bind(this);
        this.addGameDeleteRow = this.addGameDeleteRow.bind(this);
        this.generateGameDeletePanel = this.generateGameDeletePanel.bind(this);
    }

    componentDidMount() {
        this.getGameData().then(
            results => {
                this.gameData = results;
            }
        ).then(() => {
            this.unplayedGames = this.gameData.filter(gameRow => {
                return gameRow.date === null;
            })
        }).then(() => {
            return getRosterInfo(this.seasonNum);
        }).then(
            results => {
                this.rosterData = results;
                this.playerNames = Object.keys(this.rosterData);
            }
        ).then(
            () => {return this.getPokemonData()}
        ).finally(() =>this.setState({})
        )
    }

    deleteRow(e, rowIdx, key) {
        e.preventDefault()
        const newRows = this.state[key];
        newRows.splice(rowIdx, 1);
        this.setState({
            key: newRows
        });
    }

    // Game Override
    changeOverrideRowGameID(rowIdx, newGameID) {
        const newRows = this.state.gamesToOverride;
        const newGame = this.unplayedGames.filter(datum => {
            return datum.game_url_id == newGameID;
        })[0]

        newRows[rowIdx] = {
            game_id: newGameID,
            _id: newRows[rowIdx]._id,
            player1: newGame.player1,
            player2: newGame.player2,
            winner: "",
            date: "",
            time: "",
            player1Roster: ["", "", "", "", "", ""],
            player2Roster: ["", "", "", "", "", ""],
            differential: ""
        }
        this.setState({
            inputRows: newRows
        });
    }

    onOverrideSubmit(event) {
        event.preventDefault();
        var reqData = new FormData()
        console.log(JSON.stringify(this.state.gamesToOverride));
        reqData.append('overrideGames', JSON.stringify(this.state.gamesToOverride));
        this.props.submitFunc("unplayed_games", reqData)
    }

    changeOverrideRowDate(rowIdx, newDate) {
        const newRows = this.state.gamesToOverride;

        newRows[rowIdx].date = newDate;
        this.setState({
            inputRows: newRows
        });
    }

    changeOverrideRowTime(rowIdx, newTime) {
        const newRows = this.state.gamesToOverride;

        newRows[rowIdx].time = newTime;
        this.setState({
            inputRows: newRows
        });
    }

    changeOverrideRowRoster(rowIdx, rosterKey, rosterIdx, value) {
        const newRows = this.state.gamesToOverride;

        newRows[rowIdx][rosterKey][rosterIdx] = value;
        this.setState({
            inputRows: newRows
        });
    }

    changeOverrideRowDifferential(rowIdx, newDiff) {
        if (!isNumber(newDiff)) {
            return
        }
        const newRows = this.state.gamesToOverride;

        newRows[rowIdx].differential = newDiff;
        this.setState({
            inputRows: newRows
        });
    }

    changeOverrideRowWinner(rowIdx, newWinner) {
        const newRows = this.state.gamesToOverride;

        newRows[rowIdx].winner = newWinner;
        this.setState({
            inputRows: newRows
        });
    }

    renderOverrideRow(idx) {
        const row = this.state.gamesToOverride[idx]

        // Get data for games not yet played
        const dropdownOptions = [];
        this.unplayedGames.forEach(datum => {
            const playoffsStr = datum.playoffs_flag ? "[Playoffs ]" : "";
            dropdownOptions.push(<option value={datum.game_url_id}>
                {playoffsStr}Round {datum.round} {datum.player1} vs. {datum.player2}
            </option>)
        })

        // Dropdown to select winner of this game
        const winnerDropdown = <select value={row["winner"]} onChange={e => {this.changeOverrideRowWinner(idx, e.target.value)}}>
            <option value=""></option>
            <option value={row.player1}>{row.player1}</option>
            <option value={row.player2}>{row.player2}</option>
        </select>

        // Get the rosters for the players in the chosen game to generate dropdown
        const player1RosterDropdowns = [];
        const player2RosterDropdowns = [];
        ["player1", "player2"].forEach(dootKey => {
            const rosterOpts = []
            this.rosterData[row[dootKey]]["roster"].forEach(pokeID => {
                const pokeDatum = this.pokemonData.base[pokeID]
                const pokeName = pokeDatum.pokemon_name
                const rostOpt = <option value={pokeID}>{pokeName}</option>
                rosterOpts.push(rostOpt)
            })

            for (let rostIdx = 0; rostIdx < 6; ++rostIdx ) {
                const gameRosterKey = dootKey + "Roster";
                const rostPosnDropdown = <span>
                    <select value={row[gameRosterKey][rostIdx]} 
                        onChange={(e) => {this.changeOverrideRowRoster(idx, gameRosterKey, rostIdx, e.target.value)}}>
                        <option value=""></option>
                        {rosterOpts}
                    </select> &nbsp;
                </span>
                if (dootKey === "player1") {
                    player1RosterDropdowns.push(rostPosnDropdown)
                } else {
                    player2RosterDropdowns.push(rostPosnDropdown)
                }
            }
        });

        return <div className="gameOverrideRow">
            <select name={idx + "_game_id_select"} value={row.game_id}
                onChange={(e) => {this.changeOverrideRowGameID(idx, e.target.value)}}>
                {dropdownOptions}
            </select>
            <br/>
            <div className="overrideRowMisc">
                <label>Date:&nbsp;</label>
                <input type="date"
                            name={"date_" + idx}
                            value={row.date}
                            onChange={(e) => {this.changeOverrideRowDate(idx, e.target.value)}}
                        />
                <input type="time"
                    name={"time_" + idx}
                    value={row.time}
                    onChange={(e) => {this.changeOverrideRowTime(idx, e.target.value)}}
                />
                &nbsp;&nbsp;

                <label>Winner: &nbsp; {winnerDropdown}</label> &nbsp;

                <label>Differential:&nbsp;
                        <input
                            name={"differential_" + idx}
                            value={row.differential}
                            size="5"
                            onChange={(e) => {this.changeOverrideRowDifferential(idx, e.target.value)}}
                        />
                </label> &nbsp;&nbsp;
            </div>

            <div>
                <label>{row.player1}'s Roster:&nbsp;</label>
                {player1RosterDropdowns}
            </div>
            <div>
                <label>{row.player2}'s Roster:&nbsp;</label>
                {player2RosterDropdowns}
            </div>

            <button className="btn btn-danger"
            onClick={(e) => {this.deleteRow(e, idx, "gamesToOverride")}}>
                Delete Row
            </button>
        </div>
    }

    addOverrideRow() {
        const newOverride = this.state.gamesToOverride;

        const newID = this.currentOverrideID;
        this.currentOverrideID += 1;

        newOverride.push(
            {
                game_id: this.unplayedGames[0].game_url_id,
                _id: newID,
                player1: this.unplayedGames[0].player1,
                player2: this.unplayedGames[0].player2,
                winner: "",
                date: "",
                time: "",
                player1Roster: ["", "", "", "", "", ""],
                player2Roster: ["", "", "", "", "", ""],
                differential: "",
            }
        )
        this.setState({gamesToOverride: newOverride});
    }

    generateGameOverridePanel() {
        const inputRows = [];
        for (let idx = 0; idx < this.state.gamesToOverride.length; ++idx) {
            inputRows.push(this.renderOverrideRow(idx));
        }

        return <div>
                {inputRows}
            </div>
    }

    // Game Delete
    addGameDeleteRow() {
        const newDelete = this.state.gamesToDelete;

        const newID = this.currentDeleteID;
        this.currentDeleteID += 1;

        newDelete.push(
            {
                game_id: this.unplayedGames[0].game_url_id,
                _id: newID,
            }
        )
        this.setState({gamesToDelete: newDelete});
    }

    changeDeleteRowGameID(rowIdx, newGameID) {
        const newRows = this.state.gamesToDelete;

        newRows[rowIdx].game_id = newGameID;
        this.setState({gamesToDelete: newRows})
    }

    renderDeleteRow(idx) {
        const row = this.state.gamesToDelete[idx]
        const dropdownOptions = [];
        this.unplayedGames.forEach(datum => {
            const playoffsStr = datum.playoffs_flag ? "[Playoffs ]" : "";
            dropdownOptions.push(<option value={datum.game_url_id}>
                {playoffsStr}Round {datum.round} {datum.player1} vs. {datum.player2}
            </option>)
        })

        return <div className="gameOverrideRow">
                <select name={idx + "_game_id_select"} value={row.game_id}
                onChange={(e) => {this.changeDeleteRowGameID(idx, e.target.value)}}>
                {dropdownOptions}
            </select>
            &nbsp;
            <button className="btn btn-danger"
            onClick={(e) => {this.deleteRow(e, idx, "gamesToDelete")}}>
                Delete Row
            </button>

            <hr/>
        </div>
    }

    generateGameDeletePanel() {
        const inputRows = [];
        for (let idx = 0; idx < this.state.gamesToDelete.length; ++idx) {
            inputRows.push(this.renderDeleteRow(idx));
        }

        return <div>
                {inputRows}
            </div>
    }

    onGameDeleteSubmit(event) {
        event.preventDefault();
        var reqData = new FormData()
        console.log(JSON.stringify(this.state.gamesToDelete));
        reqData.append('deleteGames', JSON.stringify(this.state.gamesToDelete));
        this.props.submitFunc("unplayed_games", reqData)
    }

    // Player Delete
    addPlayerDeleteRow() {
        const newDelete = this.state.playersToDelete;

        const newID = this.currentDeleteID;
        this.currentDeleteID += 1;

        newDelete.push(
            {
                player_name: this.playerNames[0],
                _id: newID,
            }
        )
        this.setState({playersToDelete: newDelete});
    }

    changeDeletePlayerName(rowIdx, newName) {
        const newRows = this.state.playersToDelete;

        newRows[rowIdx].player_name = newName;
        this.setState({playersToDelete: newRows})

    }

    renderPlayerDeleteRow(idx) {
        const chosenName = this.state.playersToDelete[idx].player_name
        const dropdownOptions = [];
        this.playerNames.forEach(pName => {
            dropdownOptions.push(<option value={pName}>
                {pName}
            </option>)
        })

        return <div className="gameOverrideRow">
                <select name={idx + "_player_id_select"} value={chosenName}
                onChange={(e) => {this.changeDeletePlayerName(idx, e.target.value)}}>
                {dropdownOptions}
            </select> &nbsp;
            <button className="btn btn-danger"
            onClick={(e) => {this.deleteRow(e, idx, "playersToDelete")}}>
                Delete Row
            </button>
            <hr/>
        </div>
    }

    generatePlayerDeletePanel() {
        const inputRows = [];
        for (let idx = 0; idx < this.state.playersToDelete.length; ++idx) {
            inputRows.push(this.renderPlayerDeleteRow(idx));
        }

        return <div>
                {inputRows}
            </div>
    }

    onPlayerDeleteSubmit(event) {
        event.preventDefault();
        var reqData = new FormData()
        console.log(JSON.stringify(this.state.playersToDelete));
        reqData.append('deletePlayers', JSON.stringify(this.state.playersToDelete));
        this.props.submitFunc("unplayed_games", reqData)
    }

    render() {
        const addButtonDisabled = this.unplayedGames.length === 0;
        return <div className="container-fluid">
            <h1>Edit Unplayed Games</h1>
            <p>Issue changes to games that have not yet been played</p>
            <h3>Override Unplayed Games</h3>
            <button className={"btn btn-dark " + (addButtonDisabled ? "disabled": "")} 
                    disabled={addButtonDisabled}
                    onClick={this.addOverrideRow}>Add Row</button>
            <form onSubmit={(e) => {this.onOverrideSubmit(e)}}>
                {this.generateGameOverridePanel()}    
                <input className="form-submit-btn" type="submit" value="Submit" />
            </form>

            <br/>
            <h3>Delete Remaining Unplayed Games by Player</h3>
            <button className={"btn btn-dark " + (addButtonDisabled ? "disabled": "")} 
                    disabled={addButtonDisabled}
                    onClick={this.addPlayerDeleteRow}>Add Player</button>     
            <form onSubmit={(e) => {this.onPlayerDeleteSubmit(e)}}>
                {this.generatePlayerDeletePanel()}
                <input className="form-submit-btn" type="submit" value="Submit" />
            </form>

            <br/>
            <h3>Delete Single Unplayed Game</h3>
            <button className={"btn btn-dark " + (addButtonDisabled ? "disabled": "")} 
                    disabled={addButtonDisabled}
                    onClick={this.addGameDeleteRow}>Add Game</button> &nbsp;
            <form onSubmit={(e) => {this.onGameDeleteSubmit(e)}}>
                {this.generateGameDeletePanel()}
                <input className="form-submit-btn" type="submit" value="Submit" />
            </form>
            

        </div>
    }
}


class AdminPage extends React.Component {
    constructor() {
        super();
        this.viewNames = ["Create Users", "Showdown Usernames", "Add Games", "Preliminary Draft", "Roster Changes", "Recompute Stats", "Edit Unplayed Games"]
        this.submitRequest = this.submitRequest.bind(this);
        this.generateAdminSwitches = this.generateAdminSwitches.bind(this);
        this.updateAdminView = this.updateAdminView.bind(this);
        this.testAuthPage = this.testAuthPage.bind(this);

        this.views = [
            <CreateUserPanel submitFunc={this.submitRequest} />,
            <ShowdownUsernamePanel submitFunc={this.submitRequest} />,
            <GameSchedulePanel submitFunc={this.submitRequest} />,
            <PreliminaryDraftPanel submitFunc={this.submitRequest} />,
            <RosterChangePanel submitFunc={this.submitRequest} />,
            <RecomputeGameStats submitFunc={this.submitRequest} />,
            <ModifyUnplayedGames submitFunc={this.submitRequest} />
        ]
        this.seasonNum = parseSeasonFromPath(window.location.pathname);
        this.state = {
            currentView: this.viewNames[0],
            adminPW: ""
        }
    }

    testAuthPage() {
        const api_endpoint = configData.SERVER_URL + "test_auth"
        const adminPass = this.state.adminPW
          fetch(api_endpoint, {
            method: "POST",
            body: JSON.stringify({pw: adminPass})
          })
          .then(res => {
            if (res.status !== 200) {
              alert("Invalid Password")
            } else {
              alert("Valid Password")
            }
          },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              alert(error);
            })
          .catch((error) => {
            console.warn(error);
            alert("There seems to have been a server error.");
          });
    }


    submitRequest(viewName, data) {
        // Send along the password
        data.append("pw", this.state.adminPW)

        const api_endpoint = configData.SERVER_URL + this.seasonNum + "/admin/" + viewName
        return fetch(api_endpoint, {
            method: "POST",
            body: data
          })
          .then(res => {
            if (res.status !== 200) {
                throw res;
            }
            alert("Success! Refreshing page now")
            window.location.reload(false);
          },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              alert(error);
            })
          .catch((error) => {
            console.warn(error);
            alert("There was a server error. Ensure that the data/password provided are valid")
          });
    }

    updateAdminView(newViewName) {
        this.setState({ currentView: newViewName })
    }

    generateAdminSwitches() {
        const buttons = []
        for (let i = 0; i < this.viewNames.length; ++i) {
            let sortVar = this.viewNames[i];
            let btnClass = "btn";
            if (sortVar === this.state.currentView) {
                btnClass = btnClass.concat(" btn-primary")
            } else {
                btnClass = btnClass.concat(" btn-dark")
            }

            buttons.push(<button
                onClick={() => { this.updateAdminView(sortVar) }}
                key={sortVar}
                type="button"
                className={btnClass}>
                {sortVar}</button>)
        }

        return <div className="btn-toolbar sort-button-bar" role="toolbar">
            <div className="btn-group" role="group" aria-label="Basic example">
                {buttons}
            </div>
        </div>
    }

    render() {
        return (
            <div className="basepage">
                <div className="container-fluid h-100">
                    <div className="row align-items-center my-5">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10 h-100">
                            <h1>This is the Admin Page</h1>
                            <div className="admin-pw-div">
                                <input type="text" value={this.state.adminPW}
                                    placeholder={"Enter Admin Password"}
                                    onChange={(e) => {this.setState({adminPW: e.target.value})}} />&nbsp;
                                <button className="btn btn-dark" onClick={this.testAuthPage}>Test Password</button>
                            </div>
                            {this.generateAdminSwitches()}
                            {this.views[this.viewNames.indexOf(this.state.currentView)]}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AdminPage;